import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFaresPrimary from "../components/metro-fares-primary"

class P54ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="P54"
          description="View route information and buy your ticket for the P54 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">P54 service</h1>
              <p>Oulton Drive - All schools on the route</p>
              <p>All schools on the route - Oulton Drive</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="P54 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1BK_ncFSYwTo42I7ATpsFexEl0x6SJcM&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> All schools on the route
                </p>
                <p>
                  From Leeds Road, Haigh Road, Wood Lane, Haighside Drive, Low Shops Lane, Wood Lane, Manor Road, Manor Crescent, Cotswold Road, Manor Road, Wood Lane, Church Street, Direct to Rothwell St Marys then Rothwell Primary.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="P54 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=100P2bel8Dh-pfFlv86-heXAsuOtHzBs&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> All schools on the route
                </p>
                <p>
                  From Royds Lane, Marsh Street, Carlton Lane, Stone Brig Lane, Carlton Lane, Butcher Lane, Church Street, Wood Lane, , Manor Road, Manor Crescent, Cotswold Road, Manor Road, Wood Lane, Haighside Drive, Low Shops Lane, Wood Lane, Haigh Road, Leeds Road, Oulton Drive.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Park Lane</td>
                      <td>DEP</td>
                      <td>0803</td>
                    </tr>
                    <tr>
                      <td>Tesco Express</td>
                      <td>DEP</td>
                      <td>0805</td>
                    </tr>
                    <tr>
                      <td>Styebank Lane</td>
                      <td>DEP</td>
                      <td>0807</td>
                    </tr>
                    <tr>
                      <td>Low Shops Lane</td>
                      <td>DEP</td>
                      <td>0810</td>
                    </tr>
                    <tr>
                      <td>Manor Park Shops</td>
                      <td>DEP</td>
                      <td>0815</td>
                    </tr>
                    <tr>
                      <td>Wood Lane</td>
                      <td>DEP</td>
                      <td>0817</td>
                    </tr>
                    <tr>
                      <td>Rothwell St Mary's School</td>
                      <td>DEP</td>
                      <td>0825</td>
                    </tr>
                    <tr>
                      <td>Rothwell Primary</td>
                      <td>ARR</td>
                      <td>0835</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>St Marys RC Primary Sch</td>
                      <td>DEP</td>
                      <td>1510</td>
                    </tr>
                    <tr>
                      <td>Rothwell Primary</td>
                      <td>DEP</td>
                      <td>1525</td>
                    </tr>
                    <tr>
                      <td>Leeds Road near Parkways</td>
                      <td>DEP</td>
                      <td>1545</td>
                    </tr>
                    <tr>
                      <td>Holmsley Lane/ North Lane</td>
                      <td>DEP</td>
                      <td>1555</td>
                    </tr>
                    <tr>
                      <td>Oulton Drive</td>
                      <td>ARR</td>
                      <td>1600</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFaresPrimary />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default P54ServicePage
